import { IForm } from '@/base-ui/form'

export const trainingRegisterConfig: IForm = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'nameOfTheCertificate',
      width: '450px',
      placeholder: ' '
    },
    {
      field: 'qualification',
      type: 'select',
      label: 'educationBackground',
      width: '450px',
      placeholder: ' ',
      options: [
        { title: 'doctor', value: 'doctor' },
        { title: 'master', value: 'master' },
        { title: 'undergraduate', value: 'undergraduate' },
        { title: 'juniorCollege', value: 'juniorCollege' },
        { title: 'technicalSecondary', value: 'technicalSecondary' }
      ]
    },
    {
      field: 'professionalTitle',
      type: 'select',
      label: 'jobTitle',
      width: '450px',
      placeholder: ' ',
      options:
        window.location.host === 'ru.iioe.org'
          ? [
              { title: 'teachingAssistant', value: 'teachingAssistant' },
              { title: 'lecturer', value: 'lecturer' },
              { title: 'seniorLecturer', value: 'seniorLecturer' },
              { title: 'assistantProfessor', value: 'assistantProfessor' },
              { title: 'associateProfessor', value: 'associateProfessor' },
              { title: 'professor', value: 'professor' },
              { title: 'professorProfessors', value: 'professorProfessors' },
              { title: 'honoraryProfessors', value: 'honoraryProfessors' },
              { title: 'other', value: 'other' }
            ]
          : [
              { title: 'teachingAssistant', value: 'teachingAssistant' },
              { title: 'lecturer', value: 'lecturer' },
              { title: 'seniorLecturer', value: 'seniorLecturer' },
              { title: 'assistantProfessor', value: 'assistantProfessor' },
              { title: 'associateProfessor', value: 'associateProfessor' },
              { title: 'professor', value: 'professor' },
              { title: 'professorProfessors', value: 'professorProfessors' },
              { title: 'honoraryProfessors', value: 'honoraryProfessors' },
              { title: 'esOne', value: 'esOne' },
              { title: 'esTwo', value: 'esTwo' },
              { title: 'esThree', value: 'esThree' },
              { title: 'esFour', value: 'esFour' },
              { title: 'esFive', value: 'esFive' },
              { title: 'esSix', value: 'esSix' },
              { title: 'other', value: 'other' }
            ]
    },
    {
      field: 'networkId',
      type: 'select',
      label: 'TheInaugurationOfAgency',
      width: '450px',
      placeholder: ' ',
      options: []
    },
    {
      field: 'discipline',
      type: 'select',
      label: 'major',
      width: '450px',
      placeholder: ' ',
      options: [
        { title: 'art', value: 'art' },
        { title: 'pedagogy', value: 'pedagogy' },
        { title: 'management', value: 'management' },
        { title: 'aLaw', value: 'aLaw' },
        { title: 'literature', value: 'literature' },
        { title: 'agronomy', value: 'agronomy' },
        { title: 'military', value: 'military' },
        { title: 'philosophy', value: 'philosophy' },
        { title: 'computer', value: 'computer' },
        { title: 'otherO', value: 'otherO' }
      ]
    }
  ],
  colLayout: { span: 24 }
}
