
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const titles = [
      {
        title: 'English',
        url: 'http://www.iioe.org?language=en-US'
      },
      {
        title: '中文',
        url: 'http://www.iioe.org?language=zh-CN'
      },
      {
        title: 'Français',
        url: 'http://www.iioe.org?language=fr-FR'
      },
      {
        title: 'عربى',
        url: 'https://ar.iioe.org/'
      },
      {
        title: 'Русский',
        url: 'https://ru.iioe.org/'
      },
      {
        title: 'Español',
        url: 'https://es.iioe.org/'
      }
    ]
    let selectIndex = ref(4)
    if (window.location.host === 'ru.iioe.org') {
      selectIndex = ref(4)
    } else {
      selectIndex = ref(5)
    }
    const getSelectIndex = (index: number) => {
      console.log('语言', index)
      selectIndex.value = index
    }

    return {
      titles,
      selectIndex,
      getSelectIndex
    }
  }
})
