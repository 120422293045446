
import { defineComponent, ref, computed } from 'vue'
import { trainingRegisterConfig } from './config/training-register.config'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import NavLink from '@/components/nav-header/src/nav-link.vue'
import hhRequest from '@/service'

export default defineComponent({
  components: {
    NavLink
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const {
      params: { urlKey }
    } = useRoute()

    // 网络请求
    store.dispatch('training/getInaugurationOfAgencyAction')
    // store.dispatch('training/getTrainingApplyDataAction', {
    //   url: `/training/${urlKey}/apply`
    // })

    // 从vuex中获取数据
    // 获取下拉菜单
    let networkObject: any[] = []
    const network: any[] = []
    const networkName = ref('')
    async function getNetwork() {
      await hhRequest
        .get({
          url: '/network/all'
        })
        .then((res) => {
          networkObject = res
          for (const item of networkObject) {
            const dict = { title: '', value: 0 }
            dict.title = (item as any).name
            dict.value = (item as any).id
            network.push(dict)
          }
          // const dictOther = {
          //   title: window.location.host === 'ru.iioe.org' ? 'Другое' : 'Otras',
          //   value: network.length + 1
          // }
          // if (network.length > 0) {
          //   network.push(dictOther)
          // }
        })
    }
    getNetwork()

    const getNetworkName = (value: string) => {
      networkName.value = value
    }

    const formItems = trainingRegisterConfig?.formItems ?? []
    const formOriginData: any = {}
    for (const item of formItems) {
      if (item.field === 'networkId') {
        if (networkName.value) {
          formOriginData['networkName'] = ''
        } else {
          formOriginData[item.field] = ''
        }
      } else {
        formOriginData[item.field] = ''
      }
      if (item.label === 'TheInaugurationOfAgency') {
        item.options = network
      }
    }
    const formData = ref(formOriginData)

    const disabled = computed(() => {
      return !(formData.value.name &&
      formData.value.qualification &&
      formData.value.professionalTitle &&
      formData.value.networkId === 0
        ? formData.value.networkName
        : formData.value.networkId && formData.value.discipline)
    })
    // const submitData = { ...formData.value }

    const submit = async () => {
      console.log('hhhhhhhh', formData.value)
      store.dispatch('training/trainingSubmitAction', {
        url: `/training/${urlKey}/apply`,
        user: formData.value
      })

      await router.replace(`/training/${urlKey}`)
    }

    return {
      trainingRegisterConfig,
      formData,
      network,
      getNetworkName,
      disabled,
      submit
    }
  }
})
