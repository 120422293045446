import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2a11d562")
const _hoisted_1 = { class: "link" }
const _hoisted_2 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titles, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item,
          class: _normalizeClass({ active: index === _ctx.selectIndex }),
          onClick: ($event: any) => (_ctx.getSelectIndex(index))
        }, [
          _createVNode(_component_el_link, {
            href: item.url,
            target: "_self",
            underline: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1032, ["href"])
        ], 10, _hoisted_2))
      }), 128))
    ])
  ]))
}